import styled from 'styled-components';
import {Link} from 'gatsby';
import Colours from '../../constants/colours';
// @ts-ignore
import loader from '../../images/button-loader.gif';
import Typography from '../../constants/typography';
import {Button} from '../Button';

interface LabelProps {
  error: boolean;
}

export const Label = styled.label<LabelProps>`
  display: block;
  font-size: ${Typography.form.fontSize};
  font-weight: bold;
  color: ${(props) => (props.error ? Colours.red : Colours.black)};
  margin-top: 1em;
`;

interface FieldProps {
  error: boolean;
  iconName: string;
}

export const Field = styled.input<FieldProps>`
  display: block;
  font-size: ${Typography.form.fontSize};
  padding: 12px;
  padding-left: 42px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  background-image: url('/images/icons/${(props) => props.iconName}');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 12px center;

  &&& {
    border: 2px solid
      ${(props) => (props.error ? Colours.red : Colours.grey[400])};
    transition: border 0.3s;
  }

  &&&:focus {
    border: 2px solid ${Colours.green};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1em;
`;

export const ErrorMessage = styled.div`
  background-color: ${Colours.red};
  color: ${Colours.white};
  font-weight: bold;
  padding: 1em;
  text-align: center;
`;

export const SubmittingButton = styled(Button)`
  background-color: ${Colours.grey[100]};
  cursor: default;
  pointer-events: none;
  color: transparent;
  background-image: url(${loader});
  background-repeat: no-repeat;
  background-position: center;
`;

export const ForgotLink = styled(Link)`
  font-size: 1.1rem;
  margin-top: 1rem;
  float: right;
`;

export const Para = styled.p`
  text-align: left;
`;
