import React, {useState, useRef, useEffect, FunctionComponent} from 'react';
import {getQueryParameter} from '../../utils/url';
import {Button} from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import StudioAccountOptions from '../StudioAccountOptions';
import {isValidEmail} from '../../utils/validators';
import {useAuth} from '../../services/auth';
import {
  Label,
  Field,
  ButtonWrapper,
  ErrorMessage,
  SubmittingButton,
  ForgotLink,
  Para,
} from './WebLogin.styles';

interface WebLoginProps {
  apiUrl: string;
  onRedirect(url: string): void;
  continue: string;
}

interface Errors {
  email?: string;
  password?: string;
  message?: string;
}

export const WebLogin: FunctionComponent<WebLoginProps> = (props) => {
  const [errors, setErrors] = useState<Errors>({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [continueParam, setContinueParam] = useState('');
  const {login} = useAuth();

  const wrapperRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const submit = (e: React.FormEvent) => {
    setErrors({});
    setHasSubmitted(true);
    authenticate(validate());
    e.preventDefault();
  };

  const validate = (existing?: Errors) => {
    const errors: Errors = {};

    if (isValidEmail(email) === false) {
      errors.email = 'Please enter a valid email address';
    }

    if (password.length === 0) {
      errors.password = 'Please enter your password';
    }

    if (existing) {
      errors.message = existing.message;
    }

    return errors;
  };

  const authenticate = async (errors: Errors) => {
    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setIsSubmitting(true);

      await login(props.apiUrl, {
        email,
        password,
      });

      onCompleted();
    } catch (error) {
      console.error(error);
      if (error instanceof TypeError) {
        setErrors({
          message:
            'Unable to log you in, please check your internet connection',
        });
      } else if (error && error.status >= 500) {
        setErrors({message: 'Sorry, there was an unknown server error'});
      } else {
        setErrors({message: 'Sorry, your email or password are incorrect'});
      }
    }

    setIsSubmitting(false);
  };

  const onCompleted = () => {
    const continueUrl = getQueryParameter('continue');
    const redirectTo = continueUrl ? continueUrl : '/';

    props.onRedirect(`${window.location.origin}${redirectTo}`);
  };

  useEffect(() => {
    const continueUrl = getQueryParameter('continue');

    if (continueUrl) {
      setContinueParam(window.location.search);
    }
  }, []);

  return (
    <div ref={wrapperRef}>
      <form onSubmit={submit} data-test-id="login-form">
        <StudioAccountOptions>
          <Para>
            You can also log in with your Focusrite or Novation account
          </Para>
        </StudioAccountOptions>
        {errors.message ? (
          <ErrorMessage data-test-id="login-error-message">
            {errors.message}
          </ErrorMessage>
        ) : (
          ''
        )}
        <Label
          htmlFor="email"
          data-test-id="email-label"
          error={Boolean(errors.email)}
        >
          {errors.email || 'Email address'}
        </Label>
        <Field
          ref={emailRef}
          type="email"
          name="email"
          id="email"
          value={email}
          error={Boolean(errors.email)}
          onBlur={() => (hasSubmitted ? setErrors(validate(errors)) : null)}
          onChange={(e) => setEmail(e.target.value)}
          data-test-id="email-input"
          iconName="input-user.svg"
        />
        <Label
          htmlFor="password"
          data-test-id="password-label"
          error={Boolean(errors.password)}
        >
          {errors.password || 'Password'}
        </Label>
        <Field
          ref={passwordRef}
          type="password"
          name="password"
          id="password"
          value={password}
          error={Boolean(errors.password)}
          onBlur={() => (hasSubmitted ? setErrors(validate(errors)) : null)}
          onChange={(e) => setPassword(e.target.value)}
          data-test-id="password-input"
          iconName="input-password.svg"
        />
        <ForgotLink to="/reset-password/">Forgotten your password?</ForgotLink>
        <ButtonWrapper>
          {isSubmitting ? (
            <SubmittingButton>Logging in...</SubmittingButton>
          ) : (
            <Button data-test-id="login-button" onClick={submit}>
              Log In
            </Button>
          )}
        </ButtonWrapper>
      </form>
      <ExternalLink
        title="Create an account"
        href={`/register${continueParam}`}
        classNames={['external_link']}
        openTab={false}
      >
        New to Ampify? Create an account
      </ExternalLink>
    </div>
  );
};
