import React from 'react';
import {graphql, PageProps} from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import AmpifyLogo from '../components/svg/AmpifyLogo';
import {WebLogin} from '../components/WebLogin';
import {shouldInjectCountly, getCountlySrc} from '../utils/countly';
import {getBuildEnvFromData} from '../utils';
import {webLoginPageQuery} from './__generated__/webLoginPageQuery';

interface LoginProps extends PageProps<webLoginPageQuery> {
  onRedirect: (url: string) => void;
}

const Login = (props: LoginProps) => {
  const title = 'Log in to your account';
  return (
    <>
      <Helmet>
        {shouldInjectCountly() ? (
          <script
            type="text/javascript"
            src={getCountlySrc(getBuildEnvFromData(props.data))}
          ></script>
        ) : null}
      </Helmet>
      <SEO title={title} />
      <Wrapper>
        <AmpifyLogo />
        <Header>{title}</Header>
        <WebLogin
          continue=""
          apiUrl={props.data.site.siteMetadata.apiUrl}
          onRedirect={props.onRedirect}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
`;

const Header = styled.h1``;

export const pageQuery = graphql`
  query webLoginPageQuery {
    site {
      siteMetadata {
        apiUrl
        buildEnv
      }
    }
  }
`;

export default Login;
