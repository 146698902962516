import React, {ReactNode} from 'react';

interface ExternalLinkProps {
  openTab: boolean;
  classNames: string[];
  title: string;
  href: string;
  children: ReactNode;
  testId?: string;
}

const ExternalLink = (props: ExternalLinkProps) => {
  const target = props.openTab ? {target: '_blank'} : {};
  return (
    <a
      data-test-id={props.testId}
      title={props.title}
      className={props.classNames.join(' ')}
      href={props.href}
      rel="noopener noreferrer"
      {...target}
    >
      {props.children}
    </a>
  );
};

ExternalLink.defaultProps = {
  openTab: true,
};

export default ExternalLink;
